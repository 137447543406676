import React, { useEffect, useMemo, useState } from "react";
import NotFoundPage from "@/pages/404";
import { sbEditable } from "@storyblok/storyblok-editable";
import { CONTENT_TYPE_COMPONENT_MAP } from "@/utils/mapping";
import { graphql } from "gatsby";
import useStoryblok from "@/components/storyblok";

const App = () => {
  const story = useStoryblok();

  const renderDom = useMemo(() => {
    if (
      story &&
      typeof CONTENT_TYPE_COMPONENT_MAP[story?.content?.component] !==
        "undefined"
    ) {
      const Component = CONTENT_TYPE_COMPONENT_MAP[story.content.component];
      return (
        <div {...sbEditable(story?.content)}>
          <Component data={story} />
        </div>
      );
    } else {
      return <NotFoundPage />;
    }
  }, [story]);

  return renderDom;
};

export default App;

export const query = graphql`
  query ($tag_list: [String], $full_slug: String, $field_component: String) {
    allStoryblokEntry(
      filter: {
        tag_list: { in: $tag_list }
        full_slug: { eq: $full_slug }
        field_component: { eq: $field_component }
      }
      sort: { fields: published_at, order: DESC }
    ) {
      nodes {
        id
        field_component
        slug
        full_slug
        content
        name
        tag_list
        published_at(formatString: "YYYY-MM-DD HH:mm:ss")
      }
    }
  }
`;
